import React from 'react';
import isBrowser from '../utils/isBrowser';
import API from '../agents/API';
import { set as setSessionStorage, get as getSessionStorage } from '../utils/sessionStorage';
import { set as setLocalStorage } from '../utils/localStorage';

const responseInstagram = async ({ access_token }) => {
  const origin = getSessionStorage('origin');

  try {
    const { data: newContact } = await API.instagramLogin({ token: access_token });
    const { token, ...user } = newContact;

    if (token && user) {
      setSessionStorage('user', JSON.stringify(user));

      if (user.new_user) {
        setSessionStorage('token', token);
        window.location.replace('/subscribe');
      } else {
        setLocalStorage('token', token);
        window.location.replace(origin);
      }
    } else {
      window.location.replace(origin);
    }
  } catch (e) {
    window.location.replace(origin);
  }
};

export const InstagramCallbackPage: React.StatelessComponent<any> = () => {
  if (!isBrowser()) {
    return null;
  }

  const [, token] = window.location.hash.split('=');

  responseInstagram({ access_token: token });

  return <></>;
};

export default InstagramCallbackPage;
